import { Anchor } from '@components/atoms/Anchor';
import GridBox from '@components/atoms/GridBox';
import StarIcon from '@components/atoms/icons/StarIcon';
import Section from '@components/atoms/Section';
import Spacing from '@components/atoms/Spacing';
import {
  Heading2,
  LightParagraph,
  Paragraph,
} from '@components/atoms/Typography';
import BREAKPOINTS from '@lib/constants/breakpoints';
import COLORS from '@lib/constants/colors';
import LINKS from '@lib/constants/links';
import React from 'react';
import styled from 'styled-components';

function QuoteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none">
      <path
        fill="#474A57"
        fillRule="evenodd"
        d="M15.93 2.187c-2.386 1.213-3.58 2.642-3.58 4.282 1.017.121 1.858.551 2.523 1.29.665.74.997 1.595.997 2.566 0 1.033-.322 1.904-.968 2.612-.645.709-1.457 1.063-2.435 1.063-1.095 0-2.043-.46-2.845-1.382-.802-.921-1.203-2.04-1.203-3.356 0-3.947 2.132-7.035 6.395-9.262l1.115 2.187zm-8.42 0C5.104 3.4 3.902 4.829 3.902 6.469c1.036.121 1.887.551 2.552 1.29.665.74.997 1.595.997 2.566 0 1.033-.327 1.904-.983 2.612C5.813 13.646 4.997 14 4.018 14c-1.094 0-2.038-.46-2.83-1.382C.396 11.697 0 10.578 0 9.262 0 5.315 2.122 2.227 6.366 0L7.51 2.187z"
        clipRule="evenodd"
        opacity="0.3"
      />
    </svg>
  );
}

const Wrapper = styled(Section)`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  h2 {
    margin-top: 16px;
  }
`;

const ReviewCard = styled.div`
  background-color: #fff;
  width: 338px;
  height: 220px;
  box-shadow: rgb(0 0 0 / 10%) 0px 12px 16px;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin: 15px;
  box-sizing: content-box;
  justify-content: space-between;

  p {
    font-size: 14px;
    line-height: 2;
  }
`;

const ReviewWrapper = styled.div`
  align-self: start;
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  animation: 35000ms linear 0s infinite normal none running marquee;


  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    animation-duration: 78000ms;
  }

  &:hover {
    animation-play-state: paused;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .avatar {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    color: #fff;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.black};
    margin-right: 8px;
  }
`;

const RatingWrapper = styled.div`
  div:first-child {
    display: flex;
    align-items: center;
    margin-top: 32px;

    @media screen and (max-width: ${BREAKPOINTS.medium}) {
      justify-content: center;
    }
  }

  strong {
    font-weight: 700;
    line-height: 1.5;
  }

  span svg {
    height: 20px;
    margin: 0 5px 0;
    width: 20px;
  }

  p {
    margin-top: 8px;
    font-size: 0.875rem;
  }
`;

const Rating = () => (
  <RatingWrapper>
    <div>
      <span>
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </span>
    </div>
  </RatingWrapper>
);

const CustomerReviews = ({
  title,
  reviews,
}: {
  title: string;
  reviews: {
    text: string;
    customer: string;
  }[];
}) => (
  <Wrapper pastel>
    <Rating />
    <Heading2 center>{title}</Heading2>
    <ReviewWrapper style={{ width: 408 * reviews.length, marginTop: 16 }}>
      {[...reviews, ...reviews].map((r, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          <ReviewCard>
            <GridBox flexWrap="nowrap">
              <Spacing right={2}>
                <QuoteIcon />
              </Spacing>
              <Paragraph>{r.text}</Paragraph>
            </GridBox>
            <Spacing top={2}>
              <GridBox justifyContent="flex-start" alignItems="center">
                <div className="avatar">{r.customer[0]?.toUpperCase()}</div>
                <strong>{r.customer}</strong>
              </GridBox>
            </Spacing>
          </ReviewCard>
        </div>
      ))}
    </ReviewWrapper>
    <Spacing bottom={4} top={4}>
      <LightParagraph center>
        Nosso time sempre buscará o melhor seguro para você, tudo explicado do
        jeito que deve ser.
      </LightParagraph>
    </Spacing>
    <Anchor href={LINKS.google} style={{textAlign: 'center'}} target="_blank">
      Confira mais avaliações de clientes reais
    </Anchor>
  </Wrapper>
);

export default CustomerReviews;
