/* eslint-disable no-nested-ternary */
import PrimaryButton from '@components/atoms/buttons/PrimaryButton';
import HideCrisp from '@components/atoms/HideCrisp';
import { useIsSmall } from '@lib/hooks/mediaQuery';
import { useAmp } from 'next/amp';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  transition: bottom 0.3s ease-in-out 0s;
  z-index: 1100;
  background-color: white;
  box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  justify-content: center;

  a {
    display: block;
    width: 100%;
  }
`;

const BottomFixedButton = ({
  children,
  href,
  targetBlank,
  onClick,
  alwaysVisible = false, loading
}: {
  children: React.ReactNode;
  href: string | null;
  targetBlank?: boolean;
  onClick?: () => void;
  alwaysVisible?: boolean;
  loading?: boolean;
}) => {
  const [scrolled, setScrolled] = useState(false);
  const isAmp = useAmp();

  const isSmall = useIsSmall();

  const listener = () => {
    const { y } = document.body.getBoundingClientRect();

    setScrolled(y < -130);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    // cleanup function occurs on unmount
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('scroll', listener);
  }, []);

  const Children = (
    <PrimaryButton
      style={{ padding: '16px 32px', borderRadius: 500, margin: '0 auto' }}
      isBlock={isSmall}
      onClick={onClick}
      pink
      isLoading={loading}
    >
      {children}
    </PrimaryButton>
  );

  const show = alwaysVisible || isAmp || scrolled;

  return (
    <>
      {isSmall && <HideCrisp />}
      <Wrapper  className="bottom-fixed-button" style={{ bottom: show ? 0 : -90 }}>
        {href ? targetBlank ? (
          <a href={href} target="_blank" rel="noreferrer">
            {Children}
          </a>
        ) : href.startsWith('http') ? (
          <a href={href}>{Children}</a>
        ) : (
          <Link href={href} passHref>
            <a>{Children}</a>
          </Link>
        ) : Children}
      </Wrapper>
    </>
  );
};

export default BottomFixedButton;
