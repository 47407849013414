import { Paragraph } from '@components/atoms/Typography';
import BREAKPOINTS from '@lib/constants/breakpoints';
import COLORS from '@lib/constants/colors';
import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';

const Banner = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 32px;
  flex-wrap: wrap;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 32px;
  max-width: 1120px;
  margin: 0 auto;

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    grid-template-columns: 1fr;
    grid-gap: 24px;

    p {
      text-align: center;
    }
  }

  p {
    font-size: 24px;
    font-weight: 600;
  }
`;

const BannerGrid = styled.div`
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr;
  display: grid;
  position: relative;

  .banner-img {
    margin: 0 auto;
    height: 50px;
    width: 100%;
    position: relative;
  }

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .banner-img {
      height: 30px;
      position: relative;
    }
  }
`;

const WhoeBelievesSection = ({
  bgColor = COLORS.pastel,
}: {
  bgColor?: string;
}) => (
  <section style={{ backgroundColor: bgColor }}>
    <Banner>
      <Paragraph>Quem acredita:</Paragraph>

      <BannerGrid>
        <div className="banner-img">
          <Image
            src="/static/images/google-for-startups.png"
            alt="Logo do Google For Startups"
            layout="fill"
            objectFit="contain"
          />
        </div>
      </BannerGrid>
    </Banner>
  </section>
);

export default WhoeBelievesSection;
