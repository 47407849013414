import styled from 'styled-components';
import COLORS from 'lib/constants/colors';

export const Anchor = styled.a`
  color: ${COLORS.aqua};
  text-decoration: underline;

  :hover {
    text-decoration: underline;
  }
`;
